/**
 * Created by phuongho on 7/12/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class GenreModel extends BaseModel {
  @Json('title')
  public title: string = undefined;

  @Json('image')
  public image: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    this.addRule('title', 'required', true, this._t('Title can not be blank.'));
    return this.getRules();
  }
}
