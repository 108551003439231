import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { BaseService } from './base.service';
import * as Promise from 'bluebird';
import { FIREBASE_DATABASE, HTTP, HEADERS, REST_API } from './../modules/constants';
import { BehaviorSubject } from 'rxjs';
import { mergeMapTo } from 'rxjs/operators';
import { UserIdentityService } from './user_identity.service';

@Injectable()
export class AppFireBaseService extends BaseService {
    public subscriptions: any[] = [];
    public token: string;
    private user: any;
    private currentUser: firebase.User;
    public cloudFunctionUrl: string;
    public currentNotificationMessage = new BehaviorSubject(null);

    constructor(
        protected _http: TransferHttp,
        public db: AngularFireDatabase,
        public afs: AngularFirestore,
        public afAuth: AngularFireAuth,
        public afMessaging: AngularFireMessaging) {
        super(_http);
        this.cloudFunctionUrl = `https://us-central1-${_http.config.getSettings('firebase.projectId')}.cloudfunctions.net`;
    }

    public initListenData() {
        this.user = this.afAuth.authState;
        this.user.subscribe((user: firebase.User) => {
            this.currentUser = user;
            if (user) {
                this.saveMessagingDeviceToken();
                this.updateOnlineStatus();
                this.receiveNotificationMessage();
            }
        });
    }

    public updateOnlineStatus() {
        if (!this.currentUser || !this.currentUser.uid) {
            return;
        }

        const uid = this.currentUser.uid;

        const userStatusDatabaseRef = firebase.database().ref('/status/' + uid);
        const isOfflineForDatabase = {
            online: 'offline',
            lastTimeLogin: firebase.database.ServerValue.TIMESTAMP,
        };

        const isOnlineForDatabase = {
            online: 'online',
            lastTimeLogin: firebase.database.ServerValue.TIMESTAMP,
        };
        firebase.database().ref('.info/connected').on('value', function (snapshot) {
            if (snapshot.val() === false) {
                return;
            };
            userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase)
                .then(function () {
                    userStatusDatabaseRef.set(isOnlineForDatabase);
                });
        });

    }

    public loginByToken(token: string) {
        return this.afAuth.auth.signInWithCustomToken(token);
    }

    public logout() {
        return this.afAuth.auth.signOut();
    }




    public updateUsersFirestoreOnline() {
        const userStatusFirestoreRef = firebase.firestore().doc('/status/' + this.currentUser.uid);
        const temp = this;
        userStatusFirestoreRef.onSnapshot(function (doc) {
            const isOnline = doc.data().state === 'online';
            if (isOnline) {
                temp.updateOnline();
            } else {
                temp.updateOffline();
            }
        });
    }

    public updateOnline() {
        if (!this.currentUser || !this.currentUser.uid) {
            return;
        }
        const historyRef = firebase.firestore().collection(FIREBASE_DATABASE.USERS).doc(this.currentUser.uid);

        historyRef.set({ 'online': 'online' }, { merge: true });
    }

    public updateOffline() {
        if (!this.currentUser || !this.currentUser.uid) {
            return;
        }
        const historyRef = firebase.firestore().collection(FIREBASE_DATABASE.USERS).doc(this.currentUser.uid);

        historyRef.set({ 'online': 'offline' }, { merge: true });
    }

    public getToken(): Promise<string> {
        if (!this.currentUser || !this.currentUser.uid) {
            return;
        }
        return this.afAuth.auth.currentUser.getIdToken();
    }

    public post(url: string, body: any = {}, options: any = {}) {
        return this.getToken()
            .then(token => {
                if (!options.headers) {
                    options.headers = {};
                }

                options.headers['Authorization'] = 'Bearer ' + token;

                return this.http.post(url, body, options);
            })
            .then(res => {
                return res.body;
            });
    }

    public put(url: string, body: any = {}, options: any = {}) {
        return this.getToken()
            .then(token => {
                if (!options.headers) {
                    options.headers = {};
                }

                options.headers['Authorization'] = 'Bearer ' + token;

                return this.http.put(url, body, options);
            })
            .then(res => {
                return res.body;
            });
    }

    // Saves the messaging device token to the datastore.
    public saveMessagingDeviceToken() {
        if (!this.currentUser || !this.currentUser.uid) {
            return;
        }

        return this.afMessaging.requestPermission
            .pipe(mergeMapTo(this.afMessaging.tokenChanges))
            .subscribe(
                (token) => {
                    const deviceRef = firebase.firestore().collection(FIREBASE_DATABASE.DEVICES)
                        .doc(this.currentUser.uid)
                        .collection(HTTP.HEADER.DEVICE_OS)
                        .doc(HTTP.HEADER.DEVICE_ID);

                    return deviceRef.set({ registrarId: token })
                        .then(() => {
                            if (UserIdentityService.isLoggedIn()) {
                                this.setHeader(HEADERS.REGISTRAR_ID, token);
                                // update token to server
                                return this.makeHttpPut(`${this.apiUrl}/` + REST_API.ME.UPDATE_DEVICE);
                            }
                        });
                },
                (error) => { }
            );
    }

    /**
    *
    */
    public receiveNotificationMessage() {
        this.subscriptions[Date.now()] = this.afMessaging.messages.subscribe((payload: any) => {
            this.currentNotificationMessage.next(payload.data);
        });
    }

}
