import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class AlbumModel extends BaseModel {
  @Json('title')
  public title: string = undefined;

  @Json('image')
  public image: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    return this.getRules();
  }
}
