import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';
import {
  INPUT_MAXLENGTH,
} from '../modules/constants';

export class VenueTypeModel extends BaseModel {
  @Json('name')
  public name: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();

  }

  /**
   * init validate rule
   * @returns {ValidateModel}
   */
  public initValidateRules(): ValidateModel {
    return this.getRules();
  }
}
