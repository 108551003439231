import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.
export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: 'app/pages/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'venues',
    loadChildren: 'app/pages/venues/venue.module#VenueModule'
  },
  {
    path: 'users',
    loadChildren: 'app/pages/users/user.module#UserModule'
  },
  {
    path: 'genres',
    loadChildren: 'app/pages/genres/genre.module#GenreModule'
  },
  {
    path: 'articles',
    loadChildren: 'app/pages/articles/article.module#ArticleModule'
  },
  {
    path: 'settings',
    loadChildren: 'app/pages/settings/setting.module#SettingModule'
  },
  {
    path: 'account',
    loadChildren: 'app/pages/account/account.module#AccountModule'
  },
  {
    path: 'musics',
    loadChildren: 'app/pages/musics/music.module#MusicModule'
  },
];