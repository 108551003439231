import { AlbumModel } from './album.model';
import { GenreModel } from './genre.model';
import { ArtistModel } from './artist.model';
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class MusicModel extends BaseModel {
  @Json("title")
  public title: string = undefined;

  @Json("artistId")
  public artistId: string = undefined;

  @Json({ name: "artist", clazz: ArtistModel, omitEmpty: true })
  public artist: ArtistModel = undefined;

  @Json("genreId")
  public genreId: string = undefined;

  @Json({ name: "genre", clazz: GenreModel, omitEmpty: true })
  public genre: GenreModel = undefined;

  @Json("albumId")
  public albumId: string = undefined;

  @Json({ name: "album", clazz: AlbumModel, omitEmpty: true })
  public album: AlbumModel = undefined;

  @Json("trackNumber")
  public trackNumber: string = undefined;

  @Json("copyright")
  public copyright: string = undefined;

  @Json("databaseId")
  public databaseId: string = undefined;

  @Json("isrc")
  public isrc: string = undefined;

  @Json("isni")
  public isni: string = undefined;

  @Json("language")
  public language: string = undefined;

  @Json("releaseCountry")
  public releaseCountry: string = undefined;

  @Json("cover")
  public cover: string = undefined;

  @Json("duration")
  public duration: string = undefined;

  @Json("link")
  public link: string = undefined;

  @Json("tags")
  public tags: string[] = undefined;

  @Json("image")
  public image: string = undefined;

  @Json("musicId")
  public musicId: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    return this.getRules();
  }
}
