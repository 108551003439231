import {
  Component,
  ViewEncapsulation,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  forwardRef,
  EventEmitter
} from '@angular/core';
import { Router, ActivatedRoute, Event } from '@angular/router';
import {
  MediaService
} from '../../services';
import {
  MediaModel
} from '../../models';
import { MetaService } from '@ngx-meta/core';
import { Location } from '@angular/common';
import { ImageHelper } from '../../helpers';
import { MAX_SIZE_UPLOAD, JsonMapper, UPLOAD_IMAGE_TYPE } from '../../modules';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper/src/image-cropper.component';
import { BaseComponent } from 'app/pages/base.component';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-upload-image',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './upload_image.html',
  providers: [
    MediaService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadImageComponent),
      multi: true
    }
  ]
})
export class UploadImageComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  @Output() validate: EventEmitter<string> = new EventEmitter<string>();
  @Input() width: number = 100;
  @Input() height: number = 100;
  @Input() minWidth: number;
  @Input() minHeight: number;
  @Input() multiple: boolean = false;
  @Input() maxFile: number = 1;
  @Input() aspectRatio: string = '';
  @Input() image: MediaModel;
  @Input() nameValue: string = '';
  @Input() ngModel: MediaModel = new MediaModel();

  private ImageHelper = ImageHelper;
  private propagateChange = (_: any) => { };
  private widthImage: number = 0;
  private heightImage: number = 0;
  public typeImage: string = 'png';
  // crop image property
  public croppedImage: any = '';
  public croppedImageBase64: any = '';
  public imageChangedEvent: any = '';
  public coordinates = { x: 0 };

  @ViewChild('imageUpload') private imageUpload;
  @ViewChild('previewImageModal') private previewImageModal;

  constructor(protected _router: Router,
    protected _route: ActivatedRoute,
    protected _meta: MetaService,
    protected _location: Location,
    protected _toastr: ToastrService,
    private _mediaService: MediaService,
    private modalService: NgbModal) {
    super(_router, _route, _meta, _location, _toastr);
  }

  ngOnInit() {
    this.image = new MediaModel();
    this.resetData();
  }

  resetData() {
    this.croppedImage = '';
  }

  writeValue(value: any) {
    if (value) {
      this.image = value;
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  }

  removeImage(media: MediaModel) {
    this.image = new MediaModel();
    this.propagateChange(this.image);
  }

  showErrorMessage(message: string) {
    if (message) {
      this.validate.emit(message);
      jQuery('#image-error').text(message);
      jQuery('#image-error').show();
    }
  }

  /**
   * show modal create
   */
  public showHideFromModal(isShow: boolean = true, $event: any = {}) {
    jQuery('#image-error').hide();

    if (isShow) {
      this.modalService.open(this.previewImageModal, { backdrop: 'static', size: "lg" });
      setTimeout(() => {
        this.imageChangedEvent = $event;
      }, 500);
    } else {
      this.resetData();
      this.modalService.dismissAll();
    }
  }

  /**
   *
   * @param event
   */
  public fileChangeEvent($event: any): void {
    let message: string;
    const image: any = new Image();
    const file: File = $event.target.files[0];
    const reader: FileReader = new FileReader();

    if (this.isValidImageUpload(file.type)) {
      if (ImageHelper.isValidFileSize(file.size)) {
        reader.onload = (loadEvent: any) => {
          image.src = loadEvent.target.result;
          this.showHideFromModal(true, $event);
        };
        reader.readAsDataURL(file);
        image.onload = function () { };
      } else {
        message = this._t(`File upload too large. Only allow {0}MB.`, MAX_SIZE_UPLOAD);
      }
    } else {
      message = this._t('Only allow file *.png , *.jpg.');
    }
    if (message) {
      this.showErrorMessage(message);
    }
  }

  /**
   * check image valid
   * get type image
   * @param type
   */
  public isValidImageUpload(type: string) {
    const index = UPLOAD_IMAGE_TYPE.indexOf(type);
    if (index > 0) {
      this.typeImage = UPLOAD_IMAGE_TYPE[index].split('/')[1]; // get type image
    }
    return index < 0 ? false : true;
  }

  /**
   *
   * @param event
   */
  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.file;
  }

  /**
   *
   */
  public save() {
    const mediaModel = new MediaModel();
    mediaModel.image = this.croppedImage;
    this._mediaService.uploadImage(mediaModel)
      .then(item => {
        mediaModel.id = item.id;
        mediaModel.url = item.url;
        mediaModel.path = item.path;
        this.image = new MediaModel();
        this.image = mediaModel;
        this.showHideFromModal(false);
        this.propagateChange(this.image);
        jQuery('#image-error').hide();
      })
      .catch(err => {
        jQuery('#image-error').hide();
        this.setError(err);
      });
  }
}
