/**
 * Created by phuongho on 7/12/16.
 */
import { BaseModel } from './base.model';
import { Json } from '../modules';
import { ValidateModel } from './validate.model';

export class PostModel extends BaseModel {
  @Json('title')
  public title: string = undefined;

  @Json('shortDescription_en')
  public shortDescription_en: string = undefined;

  @Json('description_en')
  public description_en: string = undefined;

  @Json('shortDescription_vi')
  public shortDescription_vi: string = undefined;

  @Json('description_vi')
  public description_vi: string = undefined;

  @Json('type')
  public type: string = undefined;

  @Json('slug')
  public slug: string = undefined;

  constructor() {
    super();
    this.validateRules = new ValidateModel();
    this.initValidateRules();
  }

  public initValidateRules(): ValidateModel {
    this.addRule('title', 'required', true, this._t('Title can not be blank.'));
    return this.getRules();
  }
}
