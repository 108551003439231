/**
 * Created by phuongho on 10/16/16.
 */
import { BaseSearchModel } from './base.search.model';

export class SearchUserModel extends BaseSearchModel {
    public key: string;
    public isActive: number = 1;
    public roleId: string = '';
}
