import { JsonMapper } from '../modules/mapper/json.mapper';
import { ResponseModel, PaginationModel, UserModel, TokenModel } from '../models';
import { UtilHelper } from '../helpers/util.helper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { REST_API, ROLE } from '../modules/constants';

@Injectable()
export class UserService extends BaseService {
  constructor(public http: TransferHttp) {
    super(http);
  }


  /**
   * Logout Api
   */
  public logout(): Promise<string> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.AUTH.LOGOUT);
  }
  /**
   * Get Profile
   */
  public profile(): Promise<UserModel> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.ME.PROFILE);
  }


  /**
   * Get list user
   * @returns {Promise<T>|Promise<U>}
   */
  public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }

    const queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.USER + '?' + queryString)
      .then((res) => {

        res.data = JsonMapper.deserialize(UserModel, res.data);
        return res;
      });
  }

  public findAllUser(paging: boolean = true, filter: any = {}): any {
    if (!paging) {
      filter.offset = '';
      filter.limit = '';
    }
    const queryString = UtilHelper.parseFilterToString(filter);
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.USER + '?' + queryString)
      .then((res) => {
        return JsonMapper.deserialize(UserModel, res.data);
      });
  }


  /**
   * Get user detail
   * @returns {Promise<T>|Promise<U>}
   */
  public findById(id, filter: any = {}): Promise<UserModel> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.USER + '/' + id)
      .then((res) => {
        return JsonMapper.deserialize(UserModel, res);
      });
  }

  /**
   * Get profile
   * @returns {Promise<TResult>}
   */
  public getProfile(): Promise<UserModel> {

    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.ME.PROFILE)
      .then((res) => {
        return JsonMapper.deserialize(UserModel, res);
      });
  }

  /**
   * update user
   * @returns {Promise<T>|Promise<U>}
   */
  public updateProfile(data: UserModel): Promise<ResponseModel> {
    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.ME.PROFILE, JsonMapper.serialize(data));
  }

  /**
   * update user
   * @returns {Promise<T>|Promise<U>}
   */
  public changePassword(data: UserModel): Promise<TokenModel> {
    const obj = JsonMapper.serialize(data);

    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.ME.CHANGE_PASSWORD, obj)
      .then((res) => {
        return JsonMapper.deserialize(TokenModel, res);
      });
  }


  /**
   * create user
   * @returns {Promise<T>|Promise<U>}
   */
  public create(data: UserModel, isNoPassword: boolean = false): Promise<ResponseModel> {
    return this.makeHttpPost(`${this.apiUrl}/` + REST_API.USER + '?isNoPassword=' + isNoPassword, JsonMapper.serialize(data));
  }

  /**
   * edit user
   * @returns {Promise<T>|Promise<U>}
   */
  public update(data: UserModel): Promise<ResponseModel> {
    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.USER + '/' + data.id, JsonMapper.serialize(data));
  }

  public updateAvatar(data: UserModel): Promise<ResponseModel> {
    const obj = JsonMapper.serialize(data);

    return this.makeHttpPut(`${this.apiUrl}/` + REST_API.USER + '/' + data.id, obj);
  }

  /**
   * delete user
   * @returns {Promise<T>|Promise<U>}
   */
  public delete(id: string): Promise<ResponseModel> {
    return this.makeHttpDelete(`${this.apiUrl}/` + REST_API.USER + '/' + id);
  }

  /**
 * Get user detail
 * @returns {Promise<T>|Promise<U>}
 */
  public findUserById(id, filter: any = {}): Promise<UserModel> {
    return this.makeHttpGet(`${this.apiUrl}/` + REST_API.USER + '/' + id);
  }

  public export(filter: any = {}): Promise<any> {
    const queryString = UtilHelper.parseFilterToString(filter);
    return this.downloadFile('GET', `${this.apiUrl}/` + REST_API.USER + '/export?' + queryString)
  }
}
