import { JsonMapper } from '../modules';
import { UserIdentityService } from './user_identity.service';
import { ResponseModel } from '../models/response.model';
import {
  HTTP,
  ERROR_CODE,
  AUTHORIZATION,
  HTTP_METHOD,
  LANGUAGE,
  TIME_ZONE
} from '../modules/constants';
import { PaginationModel } from '../models/pagination.model';
import { HEADERS } from '../modules/constants';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as  fileSaver from 'file-saver';
import * as moment from 'moment-timezone';
import { UtilHelper } from '../helpers';
import { HttpHeaders } from '@angular/common/http';
@Injectable()
export class BaseService {
  public headers = new HttpHeaders();
  protected apiUrl: string;
  protected mediaUrl: string;

  constructor(public http: TransferHttp) {
    this.apiUrl = http.config.getSettings('rest.apiUrl');
    this.mediaUrl = http.config.getSettings('rest.mediaUrl');
  }
  /**
   * Set Header
   * @param key
   * @param value
   */
  public setHeader(key, value) {
    if (this.headers.has(key)) {
      this.headers = this.headers.set(key, value);
    } else {
      this.headers = this.headers.append(key, value);
    }
  }

  /**
   *
   * @param header
   */
  public removeHeader(header) {
    this.headers = this.headers.delete(header);
  }

  /**
   * Get Header Options
   * @returns {RequestOptions}
   */
  public headerOptions(): any {
    // this.setHeader(HEADERS.LANGUAGE, LANGUAGE.VIETNAM);
    // this.setHeader(HEADERS.TIMEZONE, TIME_ZONE.VIETNAM);
    // this.setHeader(HEADERS.DEVICE_ID, HTTP.HEADER.DEVICE_ID);
    // this.setHeader(HEADERS.DEVICE_OS, HTTP.HEADER.DEVICE_OS);
    // this.setHeader(HEADERS.USER_AGENT, HTTP.HEADER.USER_AGENT);

    const token = UserIdentityService.getToken();
    if (token) {
      this.setHeader(AUTHORIZATION.TYPE, `${AUTHORIZATION.METHOD} ${token}`);
    }
    return { headers: this.headers };
  }
  /**
   * Parse Data From Server Reponse
   * @param res
   * @returns {any|{}}
   */
  public parseData(res: Response): Promise<any> {
    const headers = res.headers;
    let totalItems = 0;
    let itemsPerPage = 0;
    let ret: any;
    const obj: any = res.body;

    if (res.status === 200) {
      if (obj.status === false) {
        return Promise.reject(this.parseError(obj));
      }

      if (obj.status === true) {
        return Promise.resolve(this.parseInfo(obj));
      }

      if (_.isArray(obj)) {
        if (headers.has(HEADERS.TOTAL_ITEMS) || headers.has(HEADERS.TOTAL_ITEMS.toLowerCase())) {
          const total = headers.get(HEADERS.TOTAL_ITEMS) ? headers.get(HEADERS.TOTAL_ITEMS) : headers.get(HEADERS.TOTAL_ITEMS.toLowerCase());
          // tslint:disable-next-line:radix
          totalItems = total ? parseInt(total) : 0;
        }
        if (headers.has(HEADERS.ITEM_PER_PAGE) || headers.has(HEADERS.ITEM_PER_PAGE.toLowerCase())) {
          // tslint:disable-next-line:radix
          itemsPerPage = parseInt(headers.get(HEADERS.ITEM_PER_PAGE)) ? parseInt(headers.get(HEADERS.ITEM_PER_PAGE)) : parseInt(headers.get(HEADERS.ITEM_PER_PAGE.toLowerCase()));
        }
        ret = PaginationModel.toResponse(totalItems, itemsPerPage, obj);
      } else {
        if (headers.has(HEADERS.TOTAL_ITEMS) || headers.has(HEADERS.TOTAL_ITEMS.toLowerCase())) {
          const total = headers.get(HEADERS.TOTAL_ITEMS) ? headers.get(HEADERS.TOTAL_ITEMS) : headers.get(HEADERS.TOTAL_ITEMS.toLowerCase());
          // tslint:disable-next-line:radix
          totalItems = total ? parseInt(total) : 0;
          ret = PaginationModel.toResponse(totalItems, itemsPerPage, obj);
        } else {
          ret = obj;
        }
      }
    }

    return Promise.resolve(ret);
  }

  /**
   *
   * @param error
   * @returns {ErrorObservable}
   */
  public handleError(error: any) {
    if (error.status === 0) {
      error = new ResponseModel()
      error.message = UtilHelper.translate('Could not connect to server. Try again later.');
    } else if (error && error.error != null) { // http error response
      error = error.error;
    }
    return Promise.reject(this.parseError(error));
  }


  /**
   *
   * @param error
   */
  private parseError(data: any): ResponseModel {
    let error = JsonMapper.deserialize(ResponseModel, data);

    if (error && error.code) {
      switch (error.code) {
        case ERROR_CODE.AUTHENTICATION.GENERIC:
        case ERROR_CODE.AUTHENTICATION.VIOLATE_RFC6750:
        case ERROR_CODE.AUTHENTICATION.TOKEN_EXPIRED_CODE:
        case ERROR_CODE.AUTHENTICATION.NOT_AUTHORIZED_CODE:
        case ERROR_CODE.AUTHENTICATION.INVALID_ACCESS_TOKEN_CODE:
        case ERROR_CODE.AUTHENTICATION.TOKEN_NOT_FOUND_CODE:

          UserIdentityService.clearCredentials();
          window.location.href = '#/login';
          break;

        default:
      }
    }
    return ResponseModel.init(error.code, error.message, undefined, error.title);

  }

  /**
   *
   * @param info
   */
  private parseInfo(data: any): ResponseModel {
    const info = JsonMapper.deserialize(ResponseModel, data);
    return ResponseModel.init(undefined, info.message, info.value, info.title);
  }

  /**
   * Make Http Get
   * @param url
   */
  public makeHttpGet(url: string): Promise<any> {
    return this.http.get(url, this.headerOptions())
      .then(this.parseData.bind(this))
      .catch(this.handleError.bind(this));
  }
  /**
   * Make Http Post
   * @param url
   * @param body
   */
  public makeHttpPost(url: string, body?: any): Promise<any> {
    return this.http.post(url, body, this.headerOptions())
      .then(this.parseData.bind(this))
      .catch(this.handleError.bind(this));
  }
  /**
   * Make Http Put
   * @param url
   * @param body
   */
  public makeHttpPut(url: string, body?: any): Promise<any> {
    return this.http.put(url, body, this.headerOptions())
      .then(this.parseData.bind(this))
      .catch(this.handleError.bind(this));
  }
  /**
   * Make Http Delete
   * @param url
   * @param body
   */
  public makeHttpDelete(url: string, body?: any): Promise<any> {
    return this.makeHttpRequest(HTTP_METHOD.DELETE, url, body);
  }
  /**
   * Make Http Request
   * @param method
   * @param url
   * @param body
   */
  public makeHttpRequest(method: string, url: string, body: any): Promise<any> {
    this.headerOptions();
    const options = {
      headers: this.headers,
      body: body
    };
    return this.http.request(method, url, options)
      .then(this.parseData.bind(this))
      .catch(this.handleError.bind(this));
  }

  /**
   *
   * @param res
   * @returns {Promise<any>|Promise<T>}
   */
  public handleDownloadFile(res: Response) {
    let response: any;
    const headers = res.headers;
    const disposition = headers.get(HEADERS.CONTENT_DISPOSITION) ? headers.get(HEADERS.CONTENT_DISPOSITION) : headers.get(HEADERS.CONTENT_DISPOSITION.toLowerCase());

    if (disposition && disposition.indexOf('attachment') === -1) {
      response = res.json();
      if (response.status === false) {
        return Promise.reject(this.parseError(response));
      }
    } else {
      response = res.body;
      const fileType = response.type.split("/");
      const fileNameRegex = /filename[^;=\n]*=((['']).*?\2|[^;\n]*)/;
      let fileName = `${moment().unix()}.${fileType[1]}`;
      const matches = fileNameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['']/g, '_').replace(/["]/g, '');
      }
      fileSaver.saveAs(response, fileName);
    }

    return Promise.resolve();
  }
  /**
   *
   * @param data
   */
  protected toUploadFields(data: any = {}) {
    const formData = new FormData();
    _.forEach(data, (value, key) => {
      if (value) {
        if (value instanceof File) {
          formData.append(key, value, value.name);
        } else {
          formData.append(key, value);
        }
      }
    });
    return formData;
  }

  public downloadFile(method: string, url: string, body: any = {}): Promise<any> {
    return this.http.request(method, url, {
      headers: this.headers,
      responseType: 'blob' as 'json',
      body: body
    })
      .then(this.handleDownloadFile.bind(this))
      .catch(this.handleError.bind(this));
  }
}