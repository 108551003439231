import { RouteInfo } from './sidebar.metadata';
import { ROLE } from 'app/modules';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    // {
    //     path: '/dashboard',
    //     title: 'Dashboard',
    //     icon: 'ft-home',
    //     class: '',
    //     badge: '',
    //     badgeClass: '',
    //     isExternalLink: false,
    //     submenu: [],
    //     privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    // },
    {
        path: '/venues',
        title: 'Venue List',
        icon: 'ft-map-pin',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    },
    {
        path: '/users',
        title: 'User List',
        icon: 'ft-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    },
    {
        path: '/genres',
        title: 'Genres',
        icon: 'ft-list',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    },
    {
        path: '/articles',
        title: 'Articles',
        icon: 'ft-file-text',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    },
    {
        path: '/settings',
        title: 'Settings',
        icon: 'ft-settings',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    },
    {
        path: '/musics',
        title: 'Demo Musics',
        icon: 'ft-music',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        privileges: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER]
    },
];
