import { HTTP_METHOD } from './../modules/constants';
import { MediaModel } from './../models/media.model';
import { MusicModel } from './../models/music.model';
import { JsonMapper } from '../modules/mapper/json.mapper';
import { TransferHttp } from '../modules/transfer-http/transfer-http';
import { UtilHelper } from '../helpers/util.helper';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BaseService } from './base.service';
import { PaginationModel, ResponseModel } from '../models';
import { REST_API } from '../modules/constants';

@Injectable()
export class MusicService extends BaseService {

    constructor(public http: TransferHttp) {
        super(http);
    }

    /**
     * Get list variable
     * @returns {Promise<T>|Promise<U>}
     */
    public findAll(paging: boolean = true, filter: any = {}): Promise<PaginationModel> {

        if (!paging) {
            filter.offset = '';
            filter.limit = '';
        }
        const queryString = UtilHelper.parseFilterToString(filter);
        return this.makeHttpGet(`${this.apiUrl}/` + REST_API.MUSIC_LIBRARY + '?' + queryString)
            .then((res) => {
                res.data = JsonMapper.deserialize(MusicModel, res.data);
                return res;
            });
    }

    public findById(id: string): Promise<MusicModel> {
        return this.makeHttpGet(`${this.apiUrl}/${REST_API.MUSIC}/${id}`)
            .then((res) => {
                return JsonMapper.deserialize(MusicModel, res);
            });
    }

    public create(data: MusicModel): Promise<ResponseModel> {
        return this.makeHttpPost(`${this.apiUrl}/${REST_API.MUSIC}`, JsonMapper.serialize(data));
    }

    public update(data: MusicModel): Promise<ResponseModel> {
        return this.makeHttpPut(`${this.apiUrl}/${REST_API.MUSIC}/${data.id}`, JsonMapper.serialize(data));
    }

    public delete(id: string): Promise<ResponseModel> {
        return this.makeHttpDelete(`${this.apiUrl}/${REST_API.MUSIC}/${id}`);
    }

    public uploadMp3(data: MediaModel): Promise<any> {
        const model = JsonMapper.serialize(data);
        const obj = this.toUploadFields(model);
        return this.makeHttpRequest(HTTP_METHOD.POST, `${this.apiUrl}/${REST_API.MUSIC_UPLOAD}`, obj)
            .then((res) => {
                return res;
            });
    }

    public getUploadMp3Url() {
        return `${this.apiUrl}/${REST_API.MUSIC_UPLOAD}`;
    }
}